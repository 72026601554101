<template>
  <div id="ProducerExpertTemporality">
    <QsCard :qlik-ids="['gQBWfc']" />

    <QsTabs :date="{ field: 'Jour de la commande', disableFutureDates: true }">
      <QsTab
        title="Saisonnalité des commandes"
        :qlik-ids="['jGjdDvb', 'ThYqp', 'cjNzbC']"
      />
      <QsTab :qlik-ids="['QGgqtkV']" />
    </QsTabs>

    <QsTabs :date="{ field: 'Jour du spectacle' }">
      <QsTab
        title="Saisonnalité des spectacles"
        :qlik-ids="['BXNSB', 'zEKm', 'AZVedwy']"
      />
      <QsTab :qlik-ids="['nBHGu']" />
    </QsTabs>
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";
import QsTabs from "@/components/Qlik/QsTabs";
import QsTab from "@/components/Qlik/QsTab";

export default { components: { QsCard, QsTabs, QsTab } };
</script>

<style scoped>
#ProducerExpertTemporality {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 350px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
