<template>
  <div class="tabs-navigation">
    <div
      class="tab-header"
      v-for="{ name, label, header, active, activate } in tabs"
      :class="{ 'tab-header-active': active }"
      :key="name"
      @click="activate"
    >
      <TabHeader v-if="header" :header="header" />
      <div v-else>{{ label }}</div>
    </div>
  </div>
</template>

<script>
// Display a slot content based on render function provided by $scopedSlots
const TabHeader = {
  props: { header: { type: Function, required: true } },
  render() {
    return this.header();
  }
};

export default {
  components: { TabHeader },
  inject: ["tabsContext"],
  computed: {
    tabs() {
      const {
        data: { tabs, active },
        methods: { activate }
      } = this.tabsContext;

      return tabs.map(({ name, ...rest }) => ({
        ...rest,
        name,
        active: active === name,
        activate: () => activate(name)
      }));
    }
  }
};
</script>

<style scoped>
.tabs-navigation {
  display: flex;
  padding: 5px;
}
.tab-header {
  padding: 2px 5px;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  align-items: center;
  border-bottom: 2px solid white;
}
.tab-header-active {
  color: #ff8c1e;
  border-color: #ff8c1e;
}
.tab-header:hover {
  color: #ff8c1e;
}
</style>
