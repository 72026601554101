var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Tab',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"tab-header"},[_c('el-tooltip',{staticClass:"tooltip",attrs:{"disabled":!_vm.metadata.tooltip,"placement":"top"}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(_vm.metadata.tooltip)},slot:"content"}),_c('b',[_vm._v(_vm._s(_vm.metadata.title))])]),_c('div',{staticStyle:{"flex-grow":"1","text-align":"right"}},[(!_vm.hideMenu)?_c('el-button',{staticClass:"action",staticStyle:{"color":"black"},attrs:{"type":"text"},on:{"click":_vm.toggleOverlayMenu}},[_c('fa',{attrs:{"icon":['far', _vm.overlayMenuOpen ? 'times' : 'ellipsis-v'],"size":"lg","fixed-width":""}})],1):_vm._e()],1)],1)]},proxy:true}])},[_c('div',{staticClass:"qs-objects-container",on:{"click":function($event){_vm.$mixpanel.track(
        ("Click '" + (_vm.metadata.title) + "' " + ([
            'Saisonnalité des commandes',
            'Saisonnalité des spectacles'
          ].includes(_vm.metadata.title)
            ? 'from Expert'
            : '') + " chart")
      )}}},[_vm._t("default",_vm._l((_vm.qlikIds),function(qlikId){return _c('QsSingleObject',{key:qlikId,staticStyle:{"flex-grow":"1"},attrs:{"qlik-id":qlikId}})}))],2)])}
var staticRenderFns = []

export { render, staticRenderFns }