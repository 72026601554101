<template>
  <div class="tab-body" :class="{ visible: active }">
    <slot></slot>
  </div>
</template>

<script>
import { uniqueId } from "lodash";

export default {
  name: "Tab",
  inject: ["tabsContext"],
  props: {
    name: { type: String, default: () => `tab_${uniqueId()}` },
    label: { type: String, default: "" }
  },
  mounted() {
    // Register the Tab in Tabs ancestor component
    this.tabsContext.methods.add({
      name: this.name,
      header: this.$scopedSlots.header,
      label: this.label
    });
  },
  computed: {
    active() {
      return this.tabsContext.data.active === this.name;
    }
  }
};
</script>

<style scoped>
.tab-body {
  visibility: hidden;
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}
.visible {
  visibility: visible;
}
</style>
