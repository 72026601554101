<template>
  <div class="tabs">
    <div class="tabs-navigation-container">
      <TabsNavigation style="flex-grow: 1" />
      <div :class="{ 'tabs-actions': $scopedSlots.actions() }">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="tabs-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import TabsNavigation from "./components/TabsNavigation.vue";

export default {
  components: { TabsNavigation },
  data: () => ({ tabs: [], active: "" }),
  provide() {
    const { $data: data, activate, add } = this;
    return { tabsContext: { data, methods: { add, activate } } };
  },
  methods: {
    add(tab) {
      this.tabs.push(tab);
      this.tabs[1] || (this.active = tab.name);
    },
    activate(tabName) {
      this.active = tabName;
    }
  }
};
</script>

<style scoped>
.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tabs-navigation-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.tabs-actions {
  margin-left: 5px;
  padding: 5px;
}
.tabs-container {
  flex-grow: 1;
  position: relative;
}
</style>
