<template>
  <el-card body-style="padding: 3px 5px; height: 100%" shadow="never">
    <Tabs>
      <template #actions>
        <date-range
          v-if="date"
          style="display: inline"
          :field="date.field"
          :disable-future-dates="date.disableFutureDates"
        />
      </template>
      <slot>
        <QsTab
          v-for="qlikId in qlikIds"
          :key="qlikId"
          :name="qlikId"
          :qlik-ids="[qlikId]"
        />
      </slot>
    </Tabs>
  </el-card>
</template>

<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import QsTab from "@/components/Qlik/QsTab";
import DateRange from "@/components/Qlik/DateRange";

export default {
  components: { Tabs, QsTab, DateRange },
  props: { qlikIds: { type: Array, default: () => [""] }, date: Object }
};
</script>

<style scoped></style>
