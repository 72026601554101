<template>
  <Tab>
    <template #header>
      <div class="tab-header">
        <el-tooltip
          :disabled="!metadata.tooltip"
          placement="top"
          class="tooltip"
        >
          <div slot="content" v-html="metadata.tooltip"></div>
          <b>{{ metadata.title }}</b>
        </el-tooltip>
        <div style="flex-grow: 1; text-align: right">
          <el-button
            v-if="!hideMenu"
            type="text"
            @click="toggleOverlayMenu"
            style="color: black"
            class="action"
          >
            <fa
              :icon="['far', overlayMenuOpen ? 'times' : 'ellipsis-v']"
              size="lg"
              fixed-width
            />
          </el-button>
        </div>
      </div>
    </template>
    <div
      class="qs-objects-container"
      @click="
        $mixpanel.track(
          `Click '${metadata.title}' ${
            [
              'Saisonnalité des commandes',
              'Saisonnalité des spectacles'
            ].includes(metadata.title)
              ? 'from Expert'
              : ''
          } chart`
        )
      "
    >
      <slot>
        <QsSingleObject
          v-for="qlikId in qlikIds"
          :key="qlikId"
          :qlik-id="qlikId"
          style="flex-grow: 1"
        />
      </slot>
    </div>
  </Tab>
</template>

<script>
import { getQlikMetadata } from "@/config/qlikMetadata.js";
import Tab from "@/components/Tabs/Tab.vue";
import QsSingleObject from "@/components/Qlik/QsSingleObject";

export default {
  components: { Tab, QsSingleObject },
  props: {
    qlikIds: { type: Array, default: () => [""] },
    hideMenu: { type: Boolean, default: false },
    title: String,
    tooltip: String
  },
  data: () => ({ overlayMenuOpen: false }),
  provide() {
    return { context: this.$data };
  },
  computed: {
    metadata() {
      const { title, tooltip, ...rest } = getQlikMetadata(this.qlikIds[0]);

      return {
        ...rest,
        title: this.title || title,
        tooltip: this.tooltip || tooltip
      };
    }
  },
  methods: {
    toggleOverlayMenu() {
      this.overlayMenuOpen = !this.overlayMenuOpen;
    }
  }
};
</script>

<style scoped>
.tab-header {
  display: flex;
  align-items: center;
  width: 100%;
}
.qs-objects-container {
  display: flex;
  height: 100%;
}
</style>
